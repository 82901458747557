// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-goodbye-js": () => import("./../src/pages/goodbye.js" /* webpackChunkName: "component---src-pages-goodbye-js" */),
  "component---src-pages-help-js": () => import("./../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-releases-3-1-js": () => import("./../src/pages/releases/3.1.js" /* webpackChunkName: "component---src-pages-releases-3-1-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

